$family-sans-serif:  "Ubuntu", sans-serif !default;

$text-light: #507329;
$navbar-item-img-max-height: 7rem;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 450 !default;
$weight-semibold: 500 !default;
$weight-bold: 550 !default;

$blue: #1d54ad;
